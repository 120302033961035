/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Leaflet */
@import "~leaflet/dist/leaflet.css";

/* Fonts */
@import './theme/fonts.scss';

/* Ngx-Database */
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';

a {
    text-decoration: none !important;
}
ion-button {
  text-transform: none !important; // Desactiva las mayusculas en los botones ion
}

// Paleta de colores globales
:root {
  --cuaderno-explotacion : #ffda65;
  --cuaderno-explotacion-background : #fcf4d4;
  --gestor-tareas : #71a42f;
  --gestor-tareas-background-header-table :rgb(51, 63, 79);     //R51 G63 B79
  --gestor-tareas-background-table-selected: rgb(68, 84, 106);  // R68 G84 B106
  --gestor-tareas-background-table-no-selected: rgb(217, 225, 242);  //R217 G225 B242
  --gestor-tareas-input-valid: rgb(31, 78, 120); // R31 G78 B120
  --modulo-hidraulico: #042B66;
  --modulo-medio-ambiente: 201,169,150; 
  --modulo-medio-ambiente-mensaje: rgb(112, 92, 80); 
  /*Theme Custom Properties */
  --bgBackFull: '#FCF5F0';
  --bgFrontFull: '#F2E4DC';
  --shadowColor: '#F2E4DC';
  --primaryColor: '#C9A996'; 
  --primaryDarkColor: '#9A7864';
  --primaryLightColor: '#C9A996'; 
  /*End Theme Custom Properties */
}



// Contenido
.content {
    display: grid;
    width: 100%;
    
    > .center {
        width: 98%;
        justify-self: center;
        margin-top: 5px;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        border-radius: 5px;
        background-color: rgba(255,255,255,0.5);
    }
}
/* New theme - 'boton nuevo cuaderno de campo' */
:root {
	--ion-color-botonrecursos: #4c5454;
	--ion-color-botonrecursos-rgb: 76,84,84;
	--ion-color-botonrecursos-contrast: #ffda65;
	--ion-color-botonrecursos-contrast-rgb: 255,255,255;
	--ion-color-botonrecursos-shade: #434a4a;
	--ion-color-botonrecursos-tint: #5e6565
;
}

.ion-color-botonrecursos {
	--ion-color-base: var(--ion-color-botonrecursos);
	--ion-color-base-rgb: var(--ion-color-botonrecursos-rgb);
	--ion-color-contrast: var(--ion-color-botonrecursos-contrast);
	--ion-color-contrast-rgb: var(--ion-color-botonrecursos-contrast-rgb);
	--ion-color-shade: var(--ion-color-botonrecursos-shade);
	--ion-color-tint: var(--ion-color-botonrecursos-tint);
}
:root {
	--ion-color-botontareas: #4c5454;
	--ion-color-botontareas-rgb: 76,84,84;
	--ion-color-botontareas-contrast: #ffda65;
	--ion-color-botontareas-contrast-rgb: 255,255,255;
	--ion-color-botontareas-shade: #434a4a;
	--ion-color-botontareas-tint: #5e6565
;
}

.ion-color-botontareas {
	--ion-color-base: var(--ion-color-botontareas);
	--ion-color-base-rgb: var(--ion-color-botontareas-rgb);
	--ion-color-contrast: var(--ion-color-botontareas-contrast);
	--ion-color-contrast-rgb: var(--ion-color-botontareas-contrast-rgb);
	--ion-color-shade: var(--ion-color-botontareas-shade);
	--ion-color-tint: var(--ion-color-botontareas-tint);
}

$ion-label-margin-top:-1px;
$center-margin-top: 33px;
$topbar-height-crear-modificar: 100px;
  /* New theme - 'login' */
  :root {
    --ion-color-login: #e47911;
    --ion-color-login-rgb: 228,121,17;
    --ion-color-login-contrast: #FFFFFF;
    --ion-color-login-contrast-rgb: 255,255,255;
    --ion-color-login-shade: #c96a0f;
    --ion-color-login-tint: #e78629;
  }
  /* New Class*/ 
  .ion-color-login {
      --ion-color-base: var(--ion-color-login);
      --ion-color-base-rgb: var(--ion-color-login-rgb);
      --ion-color-contrast: var(--ion-color-login-contrast);
      --ion-color-contrast-rgb: var(--ion-color-login-contrast-rgb);
      --ion-color-shade: var(--ion-color-login-shade);
      --ion-color-tint: var(--ion-color-login-tint);
  }
  /* New theme - 'btn-blue' */
  :root {
    --ion-color-btn-blue: #345578;
    --ion-color-btn-blue-rgb: 52,85,120;
    --ion-color-btn-blue-contrast: #ffffff;
    --ion-color-btn-blue-contrast-rgb: 255,255,255;
    --ion-color-btn-blue-shade: #2e4b6a;
    --ion-color-btn-blue-tint: #486686;
  }
  
  .ion-color-btn-blue {
    --ion-color-base: var(--ion-color-btn-blue);
    --ion-color-base-rgb: var(--ion-color-btn-blue-rgb);
    --ion-color-contrast: var(--ion-color-btn-blue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-btn-blue-contrast-rgb);
    --ion-color-shade: var(--ion-color-btn-blue-shade);
    --ion-color-tint: var(--ion-color-btn-blue-tint);
  }


  /* Tab-bar theme*/
  :root {
    --ion-color-tab-bar: #04a4dc ;
    --ion-color-tab-bar-rgb: 4, 164, 220;
    --ion-color-tab-bar-contrast: #000000;
    --ion-color-tab-bar-contrast-rgb: 0,0,0;
    --ion-color-tab-bar-shade: #d59f3c;
    --ion-color-tab-bar-tint: #f3bc57;
  }
  
  .ion-color-tab-bar {
    --ion-color-base: var(--ion-color-tab-bar);
    --ion-color-base-rgb: var(--ion-color-tab-bar-rgb);
    --ion-color-contrast: var(--ion-color-tab-bar-contrast);
    --ion-color-contrast-rgb: var(--ion-color-tab-bar-contrast-rgb);
    --ion-color-shade: var(--ion-color-tab-bar-shade);
    --ion-color-tint: var(--ion-color-tab-bar-tint);
  }

  // Tema para Paneles de Datos en Menu Lateral
  :root {
    --ion-color-light-dark: #292727;
    --ion-color-light-dark-rgb: 41,39,39;
    --ion-color-light-dark-contrast: #ffffff;
    --ion-color-light-dark-contrast-rgb: 255,255,255;
    --ion-color-light-dark-shade: #242222;
    --ion-color-light-dark-tint: #3e3d3d;
  }
  
  .ion-color-light-dark {
    --ion-color-base: var(--ion-color-light-dark);
    --ion-color-base-rgb: var(--ion-color-light-dark-rgb);
    --ion-color-contrast: var(--ion-color-light-dark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-light-dark-contrast-rgb);
    --ion-color-shade: var(--ion-color-light-dark-shade);
    --ion-color-tint: var(--ion-color-light-dark-tint);
  }

 
.popover-option-small, .popover-option-small-cuadrado {
  --box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  .sc-ion-select-popover-h ion-list.sc-ion-select-popover {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.popover-option {
  --min-width: 335px;
  --box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  .sc-ion-select-popover-h ion-list.sc-ion-select-popover {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.popover-option2 {
  --min-width: 285px;
  margin-right: 75px;
  --box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  .sc-ion-select-popover-h ion-list.sc-ion-select-popover {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .popover-content.sc-ion-popover-ios {
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    -ms-flex-direction: column;
    flex-direction: column;
    width: var(--width);
    min-width: var(--min-width);
    max-width: var(--max-width);
    height: var(--height);
    min-height: var(--min-height);
    max-height: var(--max-height);
    background: var(--background);
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);
    overflow: auto;
    transform: translate(-30%, 0%) !important; //mueve lateral u horizontalmente el popover
    z-index: 10;
}
}

// Colores globales mapa
:root {
  --parcelas :#375623;
  --parcelas-active :#3f5c2c;
  --hidraulica: #305496;
  --hidraulica-active: #28457c;
  --estaciones: #222b35;
  --estaciones-active: #18212c;
  --cups: #e47911;
  --cups-active: #b9620a;
  --google-earth-content : #202124;
  --toolbar : #2a2b2e;
  --content-menu : #20212481;
  --line-gris : #8a8a8d;
}

// Estilo de Letra GENERAL
ion-label {
  font-family: Arial, Helvetica, sans-serif !important;
}


// /*
// * Estilos para el LayerControl del Módulo 4SIG
// */
// .leaflet-control-layers-base , .leaflet-control-layers-overlays {
//   label {
//       div {
//         height: 30px;
//         display: grid;
//         grid-auto-flow: column;
//         gap: 2px;
//         grid-template-columns: 20px auto;
//           span {
//             display: grid;
//             grid-auto-flow: column;
//             font-family: Arial, Helvetica, sans-serif;
//             grid-template-columns: 20px auto;
//             align-items: center;
//             gap: 10px;
//             ion-icon {
//                 height: 25px;
//                 width: 25px;
//             }
//             p {
//               width: 100%;
//             }
//           }
//       }
//   }
// }

.cell_riego{
  background: #e60c0c;
}

.ngx-datatable.material.single-selection .datatable-body-row.active, .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active, .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: #c0c0c0 !important;
  //color: white !important;
  datatable-body-cell {
    color: white !important;
  }
}



// Point Socio geolocation
@keyframes fade { 
  from { 
    opacity: 0.6;
    stroke: #000000;
    fill: #000000;
    //stroke: #ff9d00;
    //fill: #c96100;
  } 
}
.blinking {
  animation: fade 800ms infinite alternate;
}


// ESTILOS PARA EL SCROLL
::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0.616);
  width: 8px;     /* Tamaño del scroll en vertical */
  //height: 8px;    /* Tamaño del scroll en horizontal */
  //display: none;  /* Ocultar scroll */
  cursor: pointer;
}

/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
::-webkit-scrollbar-thumb {
  background: #52565B;
  border-radius: 4px;
}
/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
/* Cambiamos el fondo cuando esté en active */
::-webkit-scrollbar-thumb:active {
  background-color: #cecece;
}

// SNACK-BARS

// TAREAS - .snack-bar-tareas esta deprecated
.snack-bar-tareas, .snack-bar-advertencia, .snack-bar-notificacion {
  background:  rgb(255, 218, 101) ;
  font-size: 22px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  max-height: max-content;
}
.snack-bar-advertencia {
  background: var(--ion-color-login) !important;
  
}
.snack-bar-notificacion {
  color: black;
  background: rgb(255, 218, 101) !important; // 255 218 101
}

//HIDRAULICO

.snack-bar-hidraulico {
  background:  var(--modulo-hidraulico);
  font-size: 22px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  max-height: max-content;
}
//MEDIO AMBIENTE

.snack-bar-mambiente {
  background:  var(--modulo-medio-ambiente-mensaje);
  font-size: 22px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  max-height: max-content;
}
// MODALES

// Modales para Tareas
.modal-personal, .modal-equipos, .modal-fertilizantes,
.modal-fitosanitarios, .modal-servicios  {
  .modal-wrapper {
    width: 985px;
  }
}

// Modal para Cuaderno de Campo
.modal-cuaderno {
  --background:black !important;
}

// Modal para 4SIG-Panel de Evolución
.panel-evolucion-modal {
  // Configuracion para fullscreen
/*   .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  } */
  .modal-wrapper {
    width: 80vw;
    height: 80vh;
  }
  --background: var(--google-earth-content);
}

// Modales para Turnos
.modal-turnos  {
  .modal-wrapper {
    --min-height: 525px;
  }
}
.modal-turnos-detalles  {
  .modal-wrapper {
    --min-height: 525px;
    --min-width: 785px;
  }
}
.modal-dispositivos {
  .modal-wrapper {
    --height: 240px;
    --width: 385px;
  }
}

// Estilo de los mensajes de alerta de la aplicacion
.alert, .alert-modulo-hidraulico {
  // Tamaños globales establecidos y letra
  font-family: Arial, Helvetica, sans-serif !important;
  --min-width: 350px;
  --max-width: 400px;
  --min-height: 300px;
  --max-height: 320px;

  // Estilos del header (Background puede variar según módulo)
  .alert-head {
    max-height: 70px;
    padding: 10px 5px 10px 10px;
    background-color: var(--cuaderno-explotacion);
  }
  // Estilos para titulo principal (Color puede variar dependiendo del background)
  .alert-title {
    font-family: Arial, Helvetica, sans-serif !important;
    color: #434a4a;
    font-size: 24px;
    font-weight: 500;
  }
  // Estilos para subtitulo (En caso de existir) (Color puede variar dependiendo del background)
  .alert-sub-title {
    margin-top: 30px;
    font-family: Arial, Helvetica, sans-serif !important;
    color:  #434a4a;
    font-size: 18px;
  }
  // Estilos para el mensaje
  .alert-message {
    margin: 0;
    padding: 15px 15px 10px 15px !important;
    height: 180px;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 18px;
    color: black;
    text-align: initial;
  }
  
  // Caja contenedora de botones
  .alert-button-group {
    max-height: 56px;
    justify-content: center;
  }
  // Estilos botones
  .alert-button {
    border-radius: 8px;
    background-color: #434a4a;
    color:rgb(255, 218, 101);
  }
}

// Estilo de los mensajes de alerta de  recursos
.alert-recurso{
  // Tamaños globales establecidos y letra
  font-family: Arial, Helvetica, sans-serif !important;
  --min-width: 350px;
  --max-width: 400px;
  --min-height: 300px;
  --max-height: 620px;

  // Estilos del header (Background puede variar según módulo)
  .alert-head {
    max-height: 70px;
    padding: 10px 5px 10px 10px;
    background-color: var(--cuaderno-explotacion);
  }
  // Estilos para titulo principal (Color puede variar dependiendo del background)
  .alert-title {
    font-family: Arial, Helvetica, sans-serif !important;
    color: var(--ion-text-color, rgb(76, 84, 84) );
    font-size: 24px;
    font-weight: 1000;
  }
  // Estilos para subtitulo (En caso de existir) (Color puede variar dependiendo del background)
  .alert-sub-title {
    margin-top: 0px;
    font-family: Arial, Helvetica, sans-serif !important;
    color: #dedddd;
    font-size: 18px;
  }
  // Estilos para el mensaje
  .alert-message {
    margin: 0;
    padding: 15px 15px 10px 15px !important;
    min-height: 180px;
    max-height: 620px;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 18px;
    color: black;
    text-align: initial;

    .tg {
      border-collapse:collapse;
      border-spacing:0;
    }
    // header
    .tg td{
      border-color:gray;
      border-style:solid;
      border-width:1px;
      font-family:Arial, Helvetica, sans-serif !important;
      font-size:14px;
      overflow:hidden;
      padding:10px 5px;
      word-break:normal;
    }
    .tg th{
      border-color:black;
      border-style:solid;
      border-width:1px;
      font-family:Arial, Helvetica, sans-serif !important;
      font-size:14px;
      font-weight:normal;
      overflow:hidden;
      padding:10px 5px;
      word-break:normal;
    }
    .tg .tg-lko9{
      background-color:var(--ion-color-botonrecursos);
      border-color:transparent;
      color:#ffda65;
      text-align:center;
      font-weight: bold;
      vertical-align:top
    }
    .tg .tg-baqh{
      text-align:center;
      vertical-align:top
    }
  }
  
  // Caja contenedora de botones
  .alert-button-group {
    max-height: 56px;
    justify-content: center;
  }
  // Estilos botones
  .alert-button {
    border-radius: 8px;
    background-color: var(--ion-color-botonrecursos);
    color: #ffda65;
    font-weight: bold;
  }
}
// Colores especiales para modal alerta modulo hidraulico
.alert-modulo-hidraulico {
  // Estilos del header (Background puede variar según módulo)
  .alert-head {
    background-color: var(--modulo-hidraulico) !important;
  }
  // Estilos botones
  .alert-button {
    background-color: var(--modulo-hidraulico) !important;
  }
}



//estilo modal stock categorias
.modal-categorias {
  .modal-wrapper {
    --background-color: transparent !important;
    width: calc(var(--width)) !important;
    height: calc(var(--height) - 20vh) !important;
  }

    .item ion-thumbnail {
        min-width: fit-content;
        border-color: var(--ion-color-btn-blue) !important;
        border-style: solid !important;
        min-height: 300px;
        border-width: 2px;
        border-radius: 2%;
        img {
            max-width: 5rem;    
            min-width: 5rem;
        }
    }

    .title {
      ion-item {
          border-bottom: 3px solid var(--ion-color-btn-blue);
          border-radius: 10px 10px 0px 0px;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
      }
      ion-label {
          font-family: Arial, Helvetica, sans-serif !important;
          font-size: 24px !important;
      }
  
      ion-icon{
          font-size: 24px !important;
          margin: 0px !important;
      }
  
      ion-button{
          font-family: Arial, Helvetica, sans-serif !important;
          font-size: 14px !important;
          margin-left: 7px;
      }
  }

  //flechas de seleccion
  .btn-arrow{
    cursor: pointer;
    color: var(--ion-color-btn-blue) !important;
  }

  //formulario
  .form-items{
    ion-item.item-has-focus > ion-label{
        color: var(--gestor-tareas-input-valid) !important;
    }
    ion-label {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px !important;
        font-weight: bold;
        opacity: 1 !important;
    }

    ion-input {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px !important;
        opacity: 1 !important;
    }

    .sc-ion-label-md-h {
        max-width: none !important;
    }
    .native-input:disabled{
        opacity: 10 !important;
    }
    ion-select {
        max-width: 100% !important;
    }

    // Estilos Textarea
 .item-textarea {
    ion-label {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 28px !important;
        margin-bottom: 5px;
    }
    .input-textarea {
        border: 2px solid var(--ion-color-btn-blue) !important;
        border-radius: 5px !important;
        min-height: 50px;
    }
  }

  }

  /*Posicionamiento BOTONES*/
  .buttons {
    display: grid;
    grid-auto-flow: column;
    align-content: center;
    justify-content: right;
    margin-right: 5px;
    ion-button {
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: 18px !important;
        max-width: 90%;
        height: 40px;
    }
  }
}

// Modal para stock proveedores
.modal-proveedores {
  .modal-wrapper {
    --background-color: transparent !important;
    width: calc(var(--width) + 5vw) !important;
    height: calc(var(--height) + 20vh) !important;
  }

    .item ion-thumbnail {
        min-width: fit-content;
        border-color: var(--ion-color-btn-blue) !important;
        border-style: solid !important;
        min-height: 300px;
        border-width: 2px;
        border-radius: 2%;
        img {
            max-width: 5rem;    
            min-width: 5rem;
        }
    }

    .title {
      ion-item {
          border-bottom: 3px solid var(--ion-color-btn-blue);
          border-radius: 10px 10px 0px 0px;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
      }
      ion-label {
          font-family: Arial, Helvetica, sans-serif !important;
          font-size: 24px !important;
      }
  
      ion-icon{
          font-size: 24px !important;
          margin: 0px !important;
      }
  
      ion-button{
          font-family: Arial, Helvetica, sans-serif !important;
          font-size: 14px !important;
          margin-left: 7px;
      }
  }

  //flechas de seleccion
  .btn-arrow{
    cursor: pointer;
    color: var(--ion-color-btn-blue) !important;
  }

  //formulario
  .form-items{
    ion-item.item-has-focus > ion-label{
        color: var(--gestor-tareas-input-valid) !important;
    }
    ion-label {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px !important;
        font-weight: bold;
        opacity: 1 !important;
    }

    ion-input {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px !important;
        opacity: 1 !important;
    }

    .sc-ion-label-md-h {
        max-width: none !important;
    }
    .native-input:disabled{
        opacity: 10 !important;
    }
    ion-select {
        max-width: 100% !important;
    }

    // Estilos Textarea
 .item-textarea {
    ion-label {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 28px !important;
        margin-bottom: 5px;
    }
    .input-textarea {
        border: 2px solid var(--ion-color-btn-blue) !important;
        border-radius: 5px !important;
        min-height: 50px;
    }
  }

  }

  /*Posicionamiento BOTONES*/
  .buttons {
    display: grid;
    grid-auto-flow: column;
    align-content: center;
    justify-content: right;
    margin-right: 5px;
    ion-button {
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: 18px !important;
        max-width: 90%;
        height: 40px;
    }
  }
}


// Modal para stock pedidos
.modal-pedidos {
  .modal-wrapper {
    --background-color: transparent !important;
    width: calc(var(--width) + 50vw) !important;
    height: calc(var(--height) + 20vh) !important;
  }

    .item ion-thumbnail {
        min-width: fit-content;
        border-color: var(--ion-color-btn-blue) !important;
        border-style: solid !important;
        min-height: 300px;
        border-width: 2px;
        border-radius: 2%;
        img {
            max-width: 5rem;    
            min-width: 5rem;
        }
    }

    .title {
      ion-item {
          border-bottom: 3px solid var(--ion-color-btn-blue);
          border-radius: 10px 10px 0px 0px;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
      }
      ion-label {
          font-family: Arial, Helvetica, sans-serif !important;
          font-size: 24px !important;
      }
  
      ion-icon{
          font-size: 24px !important;
          margin: 0px !important;
      }
  
      ion-button{
          font-family: Arial, Helvetica, sans-serif !important;
          font-size: 14px !important;
          margin-left: 7px;
      }
  }

  //flechas de seleccion
  .btn-arrow{
    cursor: pointer;
    color: var(--ion-color-btn-blue) !important;
  }

  //formulario
  .form-items{
    ion-item.item-has-focus > ion-label{
        color: var (--gestor-tareas-input-valid) !important;
    }
    ion-label {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px !important;
        font-weight: bold;
        opacity: 1 !important;
    }

    ion-input {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px !important;
        opacity: 1 !important;
    }

    .sc-ion-label-md-h {
        max-width: none !important;
    }
    .native-input:disabled{
        opacity: 10 !important;
    }
    ion-select {
        max-width: 100% !important;
    }

    // Estilos Textarea
 .item-textarea {
    ion-label {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 28px !important;
        margin-bottom: 5px;
    }
    .input-textarea {
        border: 2px solid var(--ion-color-btn-blue) !important;
        border-radius: 5px !important;
        min-height: 50px;
        max-width: 20px;
    }
  }

  }

  /*Posicionamiento BOTONES*/
  .buttons {
    display: grid;
    grid-auto-flow: column;
    align-content: center;
    justify-content: right;
    margin-right: 5px;
    ion-button {
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: 18px !important;
        max-width: 90%;
        height: 40px;
    }
  }
}

/* Estilos para matTooltip del menu del map */
.matTooltip-menumap {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}
/* Estilos para matTooltop de Gestor Tareas */
.matTooltip-tareas {
  background: var(--cuaderno-explotacion);
  color: black !important;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}
/* Estilos para matTooltop de Módulo 4GIS */
.matTooltip-gis {
  background: var(--hidraulica);
  color: white !important;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}

/* Paginacion de Angular material Table (Estilo General) */
.mat-paginator-container{
  .mat-select-value {
    color: black;
    font-weight: 500;
    font-size: 15px;
  }
  .mat-paginator-page-size-label, .mat-paginator-range-label {
    color: black;
    font-weight: 400;
    font-size: 15px;
  }
  .mat-select-arrow {
    color: var(--ion-color-btn-blue);
  }
}

/* Estilos para matTooltop de Sensores indices */
.matTooltip-sensores {
  background: var(--cuaderno-explotacion);
  color: black !important;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}


// Estilos para matcheckbox R 113 G 164  B 47 Requerimiento
.mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: rgb(113, 164, 47) !important;
}

// Estilos para definir el background SI NO ESTA SELECCIONADO
//.mat-checkbox.mat-accent.mat-checkbox-color-white .mat-checkbox-background {}

// Estilos para definir el borde de los mat-checkbox
// Es necesario agregar la clase .mat-checkbox-color-white al componente
.mat-checkbox.mat-accent.mat-checkbox-color-white .mat-checkbox-frame {
  border-color: white;
}


// Usado para aplicar texto en color blanco cuando no hay datos
.row-nodata:hover {
      ion-label {
          color: white !important;
      }
}


.popover-option-small-cuadrado {
  ion-label::after {
    color: black;
    content: "2";
    font-size: 16px;
    position: relative;
    right: 3px;
    bottom: 3px;
  }
}